<template>
  <div class="inner-container" :class="{ 'min-container': !getTabs }">
    <div class="page-header">
      <h1>{{ $t("Liste des factures") }}</h1>
      <!-- <b-button
        size="sm"
        variant="success"
        class="button-succes-style ml-2 float-right mb-2"
        @click="handleStore"
        ><font-awesome-icon icon="plus-circle" /> {{ $t("NEW") }}</b-button
      > -->
      <b-button
        size="sm"
        variant="success"
        class="button-succes-style ml-2 float-right mb-2"
        @click="createDevis('Libre')"
        ><font-awesome-icon icon="plus-circle" /> {{ $t("NEW") }}</b-button
      >
    </div>
    <div class="content">
      <div class="content-header">
        <searchBar
          :searchText="searchBarText"
          :searchFunc="factures"
          :filtre="{
            start_date: start,
            end_date: end,
            entreprise: current_entreprise,
          }"
          :showFiltre="true"
          @returnValue="updateSearch($event)"
        ></searchBar>

        <b-modal id="entrepriseModal" ref="entrepriseModal">
          <template #modal-header="{ close }">
            <h5>{{ $t("COMPANY_ID") }}</h5>
            <b-button size="sm" @click="close()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17.028"
                height="17.028"
                viewBox="0 0 17.028 17.028"
              >
                <path
                  id="Icon_material-close"
                  data-name="Icon material-close"
                  d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                  transform="translate(-7.5 -7.5)"
                  fill="#393939"
                />
              </svg>
            </b-button>
          </template>

          <form @submit.prevent="hideModal('entrepriseModal')">
            <div class="center">
              <b-form-group :label="$t('COMPANY_ID')" v-if="this.isSuperAdmin">
                <searchInput
                  :list="getAllentreprises"
                  :loader="getentrepriseLoading"
                  label="lib"
                  :searchFunc="all_entreprises"
                  @searchfilter="selectentreprise($event)"
                ></searchInput>
              </b-form-group>
              <b-form-group :label="$t('CUSTOMER')">
                <searchInput
                  :list="getAllclients"
                  :loader="getclientLoading"
                  label="nom"
                  :selected="filtre_client"
                  :limit="2"
                  :searchFunc="all_clients"
                  @searchfilter="selectClient($event)"
                  :placeholder="$t('CUSTOMER')"
                ></searchInput>
              </b-form-group>
            </div>
          </form>
          <template #modal-footer>
            <div class="actionModel justify-content-center">
              <b-button variant="success" @click="setup(true)">
                <div class="block-spinner">
                  {{ $t("Appliquer") }}
                </div>
              </b-button>
            </div>
          </template>
        </b-modal>

        <div class="filtre" v-b-modal.Filtre>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 24 21.688"
            class="mr-2"
          >
            <g
              id="Icon_feather-calendar"
              data-name="Icon feather-calendar"
              transform="translate(0.75 0.75)"
            >
              <path
                id="Tracé_78085"
                data-name="Tracé 78085"
                d="M6.519,6H20.65a2.019,2.019,0,0,1,2.019,2.019V22.15a2.019,2.019,0,0,1-2.019,2.019H6.519A2.019,2.019,0,0,1,4.5,22.15V8.019A2.019,2.019,0,0,1,6.519,6Z"
                transform="translate(-4.5 -3.981)"
                fill="none"
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
              <path
                id="Tracé_78086"
                data-name="Tracé 78086"
                d="M24,3V7.037"
                transform="translate(-10.878 -3)"
                fill="none"
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
              <path
                id="Tracé_78087"
                data-name="Tracé 78087"
                d="M12,3V7.037"
                transform="translate(-6.953 -3)"
                fill="none"
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
              <path
                id="Tracé_78088"
                data-name="Tracé 78088"
                d="M4.5,15H22.669"
                transform="translate(-4.5 -6.925)"
                fill="none"
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
            </g>
          </svg>
          <span v-if="start">{{ moment(start).format("DD,MMM") }} &nbsp;-</span>
          <span v-if="!start && end">x &nbsp;-</span>
          <span v-if="!start && !end">{{ $t("FILTER_PER_DATE") }}</span>
          <span v-if="end">&nbsp; {{ moment(end).format("DD,MMM") }}</span>
          <span v-if="start && !end">&nbsp; x</span>
        </div>
      </div>
      <b-modal id="Filtre" ref="Filtre" @close="resetDate" scrollable>
        <template #modal-header="{ close }">
          <h5>{{ $t("FILTER_PER_DATE") }}</h5>
          <b-button size="sm" @click="close()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.028"
              height="17.028"
              viewBox="0 0 17.028 17.028"
            >
              <path
                id="Icon_material-close"
                data-name="Icon material-close"
                d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                transform="translate(-7.5 -7.5)"
                fill="#393939"
              />
            </svg>
          </b-button>
        </template>
        <b-row>
          <b-col md="12" class="mb-4">
            <b-calendar
              v-model="start"
              @context="onContext"
              locale="fr-FR"
              block
            ></b-calendar>
          </b-col>

          <b-col md="12">
            <b-calendar
              v-model="end"
              @context="onContext"
              locale="fr-FR"
              block
            ></b-calendar>
          </b-col>
        </b-row>

        <template #modal-footer>
          <div class="double">
            <b-button variant="danger" @click="resetDate()">
              <div class="block-spinner">{{ $t("CANCEL") }}</div>
            </b-button>

            <b-button variant="success" @click="closeFilter()">
              <div class="block-spinner">
                {{ $t("CONFIRM") }}
              </div>
            </b-button>
          </div>
        </template>
      </b-modal>

      <div class="filtre">
        <p class="table-count">{{ getfactureRows }} {{ $t("INVOICES") }}</p>
        <div class="productTrie" v-if="current_entreprise">
          {{ current_entreprise.lib }}
          <font-awesome-icon
            icon="times-circle"
            class="icons"
            @click="
              filtre_entreprise = null;
              setup(true);
            "
          />
        </div>
        <div class="productTrie" v-if="currentclient">
          {{ currentclient.nom }}
          <font-awesome-icon
            icon="times-circle"
            class="icons"
            @click="
              filtre_client = null;
              setup(true);
            "
          />
        </div>
      </div>

      <div class="table-rapport-style w-100">
        <table>
          <thead>
            <tr>
              <th style="border-radius: 5px 0px 0px 0px">
                {{ $t("INVOICE") }} N°
              </th>
              <th
                v-if="
                  isSuperAdmin ||
                  isentrepriseAdmin ||
                  isAssistant ||
                  isCommercial
                "
              >
                {{ $t("CUSTOMER") }}
              </th>
              <th v-if="isSuperAdmin">
                {{ $t("ENTREPRISES") }}
              </th>
              <th>
                {{ $t("DATE") }}
              </th>
              <th>
                {{ $t("SIGNA") }}
              </th>
              <th>{{ $t("Total TTC") }}</th>
              <th>
                {{ $t("DOWNLOAD") }}
              </th>
              <th>
                {{ $t("TYPE-FAC") }}
              </th>
              <th style="border-radius: 0px 5px 0px 0px">
                {{ $t("STATUT") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              role="row"
              class="ligneNormale"
              v-for="(data, i) in getAllFacture"
              :key="i"
            >
              <td
                aria-colindex="1"
                role="cell"
                @click.prevent.stop="handleFacture(data)"
              >
                {{ data.facture.numerofactures }}
              </td>
              <td
                aria-colindex="2"
                role="cell"
                @click.prevent.stop="handleFacture(data)"
                v-if="
                  data.client &&
                  (isSuperAdmin ||
                    isentrepriseAdmin ||
                    isAssistant ||
                    isCommercial)
                "
              >
                {{ data.client.nom }} {{ data.client.prenom }}
              </td>
              <td
                aria-colindex="3"
                role="cell"
                @click.prevent.stop="handleFacture(data)"
                v-if="data.entreprise && isSuperAdmin"
              >
                {{ data.entreprise.lib }}
              </td>
              <td
                aria-colindex="4"
                role="cell"
                @click.prevent.stop="handleFacture(data)"
              >
                {{ moment(data.created_at).format("DD-MM-YYYY H:mm") }}
              </td>
              <td
                aria-colindex="5"
                role="cell"
                @click.prevent.stop="handleFacture(data)"
              >
                <span v-if="data.is_signed == 'pending'" class="encours">
                  {{ $t("PENDING") }}
                </span>
                <span v-else-if="data.is_signed == 'signed'">
                  {{ $t("SIGNED") }}
                </span>
                <!-- <span v-else-if="data.is_signed == 'stored'" class="end">
                  <font-awesome-icon icon="check" />
                </span> -->
                <span v-else class="created">
                  {{ $t("unsigned") }}
                </span>
              </td>
              <td
                aria-colindex="6"
                role="cell"
                @click.prevent.stop="handleFacture(data)"
                v-if="data.total_to_pay"
              >
                {{ data.total_to_pay | financial }}
              </td>
              <td
                aria-colindex="6"
                role="cell"
                @click.prevent.stop="handleFacture(data)"
                v-else
              >
                {{ data.total_TTC | financial }}
              </td>
              <td aria-colindex="3" role="cell">
                <div class="grey" @click="generate(data)">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 20.944 20.944"
                  >
                    <g
                      id="Icon_feather-download"
                      data-name="Icon feather-download"
                      transform="translate(-3.5 -3.5)"
                    >
                      <path
                        id="Tracé_78100"
                        data-name="Tracé 78100"
                        d="M23.444,22.5v4.21a2.1,2.1,0,0,1-2.1,2.1H6.6a2.1,2.1,0,0,1-2.1-2.1V22.5"
                        transform="translate(0 -5.37)"
                        fill="none"
                        stroke="#28367a"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      />
                      <path
                        id="Tracé_78101"
                        data-name="Tracé 78101"
                        d="M10.5,15l5.262,5.262L21.025,15"
                        transform="translate(-1.79 -3.133)"
                        fill="none"
                        stroke="#28367a"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      />
                      <path
                        id="Tracé_78102"
                        data-name="Tracé 78102"
                        d="M18,17.13V4.5"
                        transform="translate(-4.028)"
                        fill="none"
                        stroke="#28367a"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      />
                    </g>
                  </svg>
                </div>
              </td>
              <td
                aria-colindex="7"
                role="cell"
                @click.prevent.stop="handleFacture(data)"
              >
                <span v-if="data.facture.type == 'facture.acompte'">
                  {{ $t("FACT-ACOMPTE") }}
                </span>
                <span v-else>
                  {{ $t("Fac-Fin-tra") }}
                </span>
              </td>
              <td>
                <span
                  class="end"
                  style="background: #e4261b"
                  v-if="data.facture.status == 'canceled'"
                >
                  {{ $t("CANCEL") }}
                </span>
                <span v-else style="background: #ffb300" class="end">
                  {{ $t("PENDING") }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <b-modal id="factureModel" ref="factureModel" size="xl">
        <template #modal-header="{ close }" v-if="factureDetail">
          <h5>
            {{ $t("INVOICE") }}
            {{ factureDetail ? factureDetail.facture.numerofactures : " " }}
          </h5>
          <b-button size="sm" @click="close()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.028"
              height="17.028"
              viewBox="0 0 17.028 17.028"
            >
              <path
                id="Icon_material-close"
                data-name="Icon material-close"
                d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                transform="translate(-7.5 -7.5)"
                fill="#393939"
              />
            </svg>
          </b-button>
        </template>
        <div v-if="factureDetail" class="modal-test">
          <div>
            <div class="cours">
              <p
                class="end"
                style="background: #e4261b"
                v-if="factureDetail.facture.status == 'canceled'"
              >
                {{ $t("CANCEL") }}
              </p>
              <p v-else style="background: #ffb300">
                {{ $t("PENDING") }}
              </p>
              <b-button
                variant="danger"
                @click="showMsgBoxOne"
                v-if="factureDetail.facture.status != 'canceled'"
              >
                <div class="block-spinner">
                  {{ $t("ANNULER") }} {{ $t("INVOICE") }}
                </div>
              </b-button>
            </div>
          </div>
          <div class="part justify">
            <div class="divborder">
              <p>{{ $t("COORDONNEES_FACTURE") }}</p>
            </div>
            <div class="left">
              <ul>
                <h2 class="textstyle">{{ factureDetail.entreprise.lib }}</h2>
                <span class="adressinfo">
                  <span class="info textstyle">
                    {{ factureDetail.entreprise.rue }}
                  </span>
                  <span class="info textstyle">
                    {{ factureDetail.entreprise.cp }}
                    {{ factureDetail.entreprise.ville }}
                  </span>
                </span>
                <li>
                  <span class="entrepriseinfo">{{ $t("SIRET") }}</span>
                  <span class="textstyle">{{
                    factureDetail.entreprise.siret
                  }}</span>
                </li>
                <li>
                  <span class="entrepriseinfo">{{ $t("NUM_TVA") }}</span>
                  <span class="textstyle">{{
                    factureDetail.entreprise.num_tva
                  }}</span>
                </li>

                <li>
                  <span class="entrepriseinfo">{{ $t("TEL") }}</span>
                  <span>{{ factureDetail.entreprise.phone ? factureDetail.entreprise.phone.formated_phone : '' }}</span>
                </li>
                <div class="block">
                  <li class="desclass" v-if="factureDetail">
                    <span class="title" v-if="factureDetail.interlocuteur"
                      >{{ $t("INTERLOCUTEUR") }}:</span
                    >
                    <span class="textstyle" v-if="factureDetail.interlocuteur">
                      {{ factureDetail.interlocuteur.nom }}
                      {{ factureDetail.interlocuteur.prenom }}
                    </span>
                  </li>
                  <li>
                    <span class="title">{{ $t("CREATED_BY") }}:</span>
                    <span class="textstyle" v-if="factureDetail.user">
                      {{ factureDetail.user.prenom }}
                      {{ factureDetail.user.nom }}
                    </span>
                  </li>

                  <li class="desclass" v-if="factureDetail">
                    <span class="title" v-if="factureDetail.prime"
                      >{{ $t("TYPE-FAC") }}:</span
                    >
                    <span>{{ factureDetail.prime }} </span>
                  </li>
                  <li class="desclass" v-if="factureDetail">
                    <span class="title" v-if="factureDetail.created_at"
                      >{{ $t("DATE_FACTURE") }}:</span
                    >
                    <span>
                      {{
                        moment(factureDetail.created_at).format(
                          "DD-MM-YYYY H:mm"
                        )
                      }}
                    </span>
                  </li>
                </div>
              </ul>
            </div>
            <div class="right">
              <h1>{{ $t("INVOICES") }}</h1>
              <div class="infonum">
                <span>N° {{ factureDetail.facture.numerofactures }}</span>
                <span class="fact">Facturé à</span>
              </div>

              <h2 class="textstyle" v-if="factureDetail.client">
                {{ factureDetail.client.prenom }} {{ factureDetail.client.nom }}
              </h2>
              <span class="adressinfo">
                <span class="info textstyle">{{
                  factureDetail.client.rue
                }}</span>
                <span class="info textstyle">
                  {{ factureDetail.client.cp }}
                  {{ factureDetail.client.ville }}
                </span>
              </span>
              <span>
                <span class="entrepriseinfo">{{ $t("TEL") }} </span>
                <span class="info"> {{ factureDetail.client.phone ? factureDetail.client.phone.formated_phone : '' }}</span>
              </span>
              <h3 v-if="factureDetail.facture.delegataire">
                <span class="part-title"> {{ $t("DELEGATAIRE") }}: </span>
                <span>{{ factureDetail.facture.delegataire.nom }}</span>
              </h3>
            </div>
            <h3 class="description-container" v-if="factureDetail.description">
              <span class="title-description mr-1">{{ $t("DESC") }}:</span>
              <span>{{
                reddescription
                  ? factureDetail.description
                  : factureDetail.description.slice(0, 300)
              }}</span>
              <span
                class="read"
                v-if="factureDetail.description.length > 300"
                @click="reddescription = !reddescription"
              >
                ...{{ reddescription ? $t("LESS") : $t("MORE") }}
              </span>
            </h3>
            <div class="divborder">
              <p>{{ $t("DETAILS_FACTURE") }}</p>
            </div>
          </div>

          <div
            v-for="(groupe, x) in factureDetail.groupeLigneDocument"
            :key="x"
            class="group"
          >
            <div class="piece">
              <div>
                <p>{{ $t("PIECE_") }}:</p>
                <p class="textstyle" style="font-weight: 600">
                  {{ groupe.piece }}
                </p>
              </div>
              <div class="icone-down-up">
                <p v-if="!showGroup[x].bool">{{ $t("TOTAL_PIECE_TTC") }}</p>
                <span v-if="!showGroup[x].bool">
                  {{ groupe.total_TTC | financial }}
                </span>
                <b-button size="sm" @click="closeGroup(x)">
                  <font-awesome-icon
                    icon="chevron-down"
                    class="icons"
                    v-if="!showGroup[x].bool"
                  />
                  <font-awesome-icon icon="chevron-up" class="icons" v-else />
                </b-button>
              </div>
            </div>
            <div class="part" v-show="showGroup[x].bool">
              <ul>
                <li class="traveauxtitle">
                  <span class="title" style="font-weight: 600,font-size: 14px;"
                    >{{ $t("WORK") }} :
                  </span>
                  <span class="textstyle">{{ groupe.traveaux }}</span>
                </li>
              </ul>
              <table class="tabledonne">
                <thead>
                  <tr>
                    <th style="border-radius: 5px 0px 0px 0px">
                      {{ $t("REF") }}
                    </th>
                    <th>{{ $t("WORDING") }}/{{ $t("DESC") }}</th>
                    <th>{{ $t("QTE") }}</th>
                    <th>{{ $t("PUHT") }}</th>
                    <th>
                      {{ $t("TOTALHT") }}
                    </th>
                    <th v-if="factureDetail.remise_mode != 'global'">
                      {{ $t("REMISE") }}
                    </th>
                    <th v-if="factureDetail.remise_mode != 'global'">
                      {{ $t("Avec Remise") }}
                    </th>
                    <th style="border-radius: 0px 5px 0px 0px">
                      {{ $t("VAT") }}
                    </th>
                  </tr>
                </thead>
                <tbody
                  v-for="(
                    product, y
                  ) in factureDetail.GroupeLigneDocument_produit"
                  :key="y"
                >
                  <tr
                    v-if="
                      groupe.id == product.groupelignedocument_id &&
                      readActivated[y]
                    "
                  >
                    <td>
                      {{ product.ref ? product.ref : "-" }}
                    </td>

                    <td class="blocklibdesc">
                      <div class="libblock">
                        {{ product.lib ? product.lib : "" }}
                      </div>
                      <div
                        class="descblock"
                        v-if="product.desc"
                        :class="{ 'auto-heigth': readActivated[y].bool }"
                      >
                        {{
                          readActivated[y].bool
                            ? product.desc
                            : product.desc.slice(0, 105)
                        }}
                        <span
                          class="read"
                          v-if="product.desc.length > 105"
                          @click="
                            readActivated[y].bool = !readActivated[y].bool
                          "
                        >
                          ...{{
                            readActivated[y].bool ? $t("LESS") : $t("MORE")
                          }}
                        </span>
                      </div>
                    </td>
                    <td>{{ product.quantite | financial(true) }}</td>
                    <td>{{ product.unit_price | financial }}</td>
                    <td>
                      {{ (product.unit_price * product.quantite) | financial }}
                    </td>
                    <td v-if="factureDetail.remise_mode != 'global'">
                      {{ product.remise_value ? product.remise_value : 0 }}
                      {{ product.remise_type == "remise.HT" ? "€" : "%" }}
                    </td>
                    <td v-if="factureDetail.remise_mode != 'global'">
                      {{ totalAR(product) | financial }}
                    </td>
                    <td>{{ product.tva }} %</td>
                  </tr>
                </tbody>
              </table>
              <div class="flexEnd">
                <div class="sousTraitant">
                  <p v-if="groupe.sous_traitant">
                    Sous-traitant: {{ groupe.sous_traitant.name }}, SIRET:
                    {{ groupe.sous_traitant.num_siret }}, Num RGE:
                    {{ groupe.sous_traitant.num_rge }}
                  </p>
                </div>
                <div class="subtable">
                  <table class="complet">
                    <tr>
                      <td class="thead">
                        {{ $t("TOTAL_HT") }}
                      </td>
                      <td>{{ groupe.total_HT | financial }}</td>
                    </tr>
                    <tr v-if="groupe.remise">
                      <td class="thead">
                        {{ $t("REMISE") }}
                      </td>
                      <td>{{ groupe.remise | financial }}</td>
                    </tr>
                    <tr v-if="groupe.remise">
                      <td class="thead">
                        {{ $t("TOTALREMISE") }}
                      </td>
                      <td>{{ groupe.total_HT_with_remise | financial }}</td>
                    </tr>
                    <template v-if="groupe.tva_groupes.length">
                      <tr
                        v-for="tva in groupe.tva_groupes"
                        :key="'tva' + tva.id"
                      >
                        <td class="thead">TVA ({{ tva.tva }}%)</td>
                        <td style="border-radius: 0px 5px 0px 0px">
                          {{ tva.tva_montant | financial }}
                        </td>
                      </tr>
                    </template>
                    <tr>
                      <td class="thead">
                        {{ $t("TOTAL_PIECE_TTC") }}
                      </td>
                      <td>{{ groupe.total_TTC | financial }}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="flexEndTotale">
            <div class="subtableTotale">
              <table class="completTotale">
                <tr>
                  <td class="theadTotale">
                    {{ $t("TOTAL_HT") }}
                  </td>
                  <td>{{ factureDetail.total_HT | financial }}</td>
                </tr>
                <tr v-if="factureDetail.remise_value">
                  <td class="theadTotale">
                    {{ $t("REMISE") }}
                  </td>
                  <td>
                    {{ factureDetail.remise_total | financial }}
                  </td>
                </tr>
                <tr v-if="factureDetail.remise_value">
                  <td class="theadTotale">
                    {{ $t("TOTALREMISE") }}
                  </td>
                  <td>{{ factureDetail.total_ht_with_remise | financial }}</td>
                </tr>
                <template v-if="factureDetail.tva_document.length">
                  <tr
                    v-for="tva in factureDetail.tva_document"
                    :key="'tva' + tva.id"
                  >
                    <td class="theadTotale">TVA({{ tva.tva }}%)</td>
                    <td style="border-radius: 0px 5px 0px 0px">
                      {{ tva.tva_montant | financial }}
                    </td>
                  </tr>
                </template>
                <tr>
                  <td class="theadTotale">{{ $t("Total TTC") }}</td>
                  <td>{{ factureDetail.total_TTC | financial }}</td>
                </tr>
                <template v-if="factureDetail.with_aide">
                  <tr v-if="factureDetail.montant_maprimrenov">
                    <td class="theadTotale">
                      {{ $t("MAPRIME") }}
                    </td>
                    <td>
                      {{ factureDetail.montant_maprimrenov | financial }}
                    </td>
                  </tr>
                  <tr v-if="factureDetail.montant_cee">
                    <td class="theadTotale">
                      {{ $t("CEE") }}
                    </td>
                    <td>
                      {{ factureDetail.montant_cee | financial }}
                    </td>
                  </tr>
                </template>
                <tr v-if="factureDetail.facture.type == 'facture.acompte'">
                  <td class="theadTotale">
                    {{ $t("ACOMPTE") }}
                  </td>
                  <td v-if="factureDetail.facture.total_acompte">
                    {{ factureDetail.facture.total_acompte | financial }}
                  </td>
                </tr>
                <tr
                  v-if="
                    factureDetail.montant_maprimrenov ||
                    factureDetail.montant_cee ||
                    factureDetail.facture.total_acompte
                  "
                >
                  <td class="theadTotale">
                    {{ $t("Total à payer") }}
                  </td>
                  <td>
                    {{ factureDetail.total_to_pay | financial }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div
            class="table-rapport-style w-100 mt-2"
            v-if="factureDetail.facture.total_acompte"
          >
            <table class="accompteTable">
              <thead>
                <tr>
                  <th style="border-radius: 5px 0px 0px 0px">
                    {{ $t("ACOMPTE") }} N°
                  </th>
                  <th>
                    {{ $t("DESIGNATION") }}
                  </th>
                  <th>
                    {{ $t("MONTANT") }}
                  </th>
                  <th>
                    {{ $t("STATUT") }}
                  </th>
                  <th style="border-radius: 0px 5px 0px 0px">
                    {{ $t("CREATED_AT") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  role="row"
                  class="ligneNormale"
                  v-for="(data, i) in factureDetail.facture.acomptes"
                  :key="i"
                  @click="goToAcompte(data)"
                >
                  <td>{{ data.num }}</td>
                  <td>{{ $t("ACOMPTE") }}</td>
                  <td>{{ data.amount | financial }}</td>
                  <td>
                    <span
                      class="end"
                      style="background: #ffb300"
                      v-if="data.status == 'saved'"
                    >
                      {{ $t("PENDING") }}
                    </span>
                    <span v-else style="background: #e4261b" class="end">
                      {{ $t("CANCEL") }}
                    </span>
                  </td>
                  <td>
                    {{ moment(data.created_at).format("DD-MM-YYYY H:mm") }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="table-rapport-style w-100 mt-2"
            v-if="factureDetail.avoir"
          >
            <table class="accompteTable">
              <thead>
                <tr>
                  <th style="border-radius: 5px 0px 0px 0px">
                    {{ $t("AVOIR") }} N°
                  </th>
                  <th>
                    {{ $t("DESIGNATION") }}
                  </th>
                  <th style="border-radius: 0px 5px 0px 0px">
                    {{ $t("CREATED_AT") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr role="row" class="ligneNormale">
                  <td>{{ factureDetail.avoir.numero_sequence }}</td>
                  <td>{{ $t("ACOMPTE") }}</td>
                  <td>
                    {{
                      moment(factureDetail.avoir.created_at).format(
                        "DD-MM-YYYY H:mm"
                      )
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="part">
            <h3 v-if="factureDetail.remarque" class="remarqueblock">
              <span class="part-title"> {{ $t("REMARQUE") }} : </span>

              <span>
                {{
                  redremarque
                    ? factureDetail.remarque
                    : factureDetail.remarque.slice(0, 200)
                }}</span
              >
              <span
                class="read"
                v-if="factureDetail.remarque.length > 200"
                @click="redremarque = !redremarque"
              >
                ...{{ redremarque ? $t("LESS") : $t("MORE") }}
              </span>
            </h3>
            <h3 v-if="factureDetail.condition_paiment">
              <span class="part-title">
                {{ $t("Condition de paiement") }} :
              </span>
              <span>
                {{
                  redcondition_paiment
                    ? factureDetail.condition_paiment
                    : factureDetail.condition_paiment.slice(0, 90)
                }}</span
              >
              <span
                class="read"
                v-if="factureDetail.condition_paiment.length > 90"
                @click="redcondition_paiment = !redcondition_paiment"
              >
                ...{{ redcondition_paiment ? $t("LESS") : $t("MORE") }}
              </span>
            </h3>
            <h3 v-if="factureDetail.moyens_paiement">
              <span class="part-title"> {{ $t("MOYENPAIEMENT") }}:</span>

              <span> {{ $t(factureDetail.moyens_paiement) }}</span>
            </h3>
            <div v-if="factureDetail.moyens_paiement == 'financement'">
              <div class="cadre">
                <h3 v-if="factureDetail.organisme_preteur">
                  <span class="part-title">{{ $t("ORG_PRETEUR") }} :</span>

                  <span>{{ factureDetail.organisme_preteur }}</span>
                </h3>
                <h3 v-if="factureDetail.montant_credit">
                  <span class="part-title"> {{ $t("MONTANT_CREDIT") }} :</span>

                  <span>{{ factureDetail.montant_credit | financial }}</span>
                </h3>
                <h3 v-if="factureDetail.taux_nominal">
                  <span class="part-title"> {{ $t("TEAUX_NOMINAL") }} :</span>

                  <span>{{ factureDetail.taux_nominal }} %</span>
                </h3>
                <h3 v-if="factureDetail.mensualite">
                  <span class="part-title"> {{ $t("MENSUALITE") }} :</span>

                  <span>{{ factureDetail.mensualite | financial }}</span>
                </h3>
                <h3 v-if="factureDetail.nombre_mensualites">
                  <span class="part-title"> {{ $t("NBR_MENSUALITE") }} :</span>

                  <span>{{ factureDetail.nombre_mensualites }}</span>
                </h3>
                <h3 v-if="factureDetail.taeg">
                  <span class="part-title"> {{ $t("TAEG") }} :</span>
                  <span>{{ factureDetail.taeg }} %</span>
                </h3>
                <h3 v-if="factureDetail.cout_total_credit">
                  <span class="part-title"> {{ $t("CRIDIT_TOTALE") }} :</span>

                  <span>{{ factureDetail.cout_total_credit | financial }}</span>
                </h3>
              </div>
              <h3 v-if="factureDetail.assurance_type">
                <span class="part-title"> {{ $t("ASSURANCE_TYPE") }} :</span>

                <span>{{ factureDetail.assurance_type }}</span>
              </h3>
              <h3 v-if="factureDetail.montant_maprimrenov">
                <span class="part-title">{{ $t("MA_PRIME_RENOV") }} :</span>

                <span>{{ factureDetail.montant_maprimrenov | financial }}</span>
              </h3>
              <h3 v-if="factureDetail.montant_cee">
                <span class="part-title"> {{ $t("MONTANT_CEE") }} :</span>

                <span>{{ factureDetail.montant_cee | financial }}</span>
              </h3>
              <h3 v-if="factureDetail.bareme_mpr">
                <span class="part-title"> {{ $t("Barème MPR") }}: </span>
                <span>{{ factureDetail.bareme_mpr }}</span>
              </h3>
            </div>
          </div>
        </div>

        <template #modal-footer>
          <div class="buttondevis">
            <b-button
              variant="success"
              @click="signed()"
              v-if="
                factureDetail.is_signed != 'signed' &&
                !factureDetail.facture.avoir &&
                (isSuperAdmin ||
                  isentrepriseAdmin ||
                  isAssistant ||
                  isCommercial)
              "
            >
              <div class="block-spinner">
                {{ $t("YOUSIGN") }}
                <div v-if="signedLoader" class="loading ml-2">
                  <div class="spinner-border" role="status"></div>
                </div>
              </div>
            </b-button>
            <b-button
              variant="success"
              @click="openfact()"
              v-if="
                (isSuperAdmin ||
                  isentrepriseAdmin ||
                  isAssistant ||
                  isCommercial) &&
                factureDetail.type == 'facture.acompte'
              "
            >
              <div class="block-spinner">
                {{ $t("INVOICE_GENERATE") }}
              </div>
            </b-button>
            <b-button
              variant="success"
              @click="sendEmail()"
              v-if="
                isSuperAdmin || isentrepriseAdmin || isAssistant || isCommercial
              "
            >
              <div class="block-spinner">
                {{ $t("SEND_BY_EMAIL") }}
              </div>
            </b-button>

            <b-button
              variant="success"
              @click="openDev()"
              v-if="factureDetail.facture.devis"
            >
              <div class="block-spinner">
                {{ factureDetail.facture.devis.numerodevis }}
              </div>
            </b-button>

            <b-button variant="danger" @click="generate()">
              <div class="block-spinner">
                {{ $t("DOWNLOAD") }}
                
              </div>
            </b-button>
          </div>
        </template>
      </b-modal>

      <b-modal size="lg" centered id="ModelError" ref="ModelError">
        <template #modal-header="{ close }">
          <h5>{{ $t("ERROR") }}</h5>
          <b-button size="sm" @click="close()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.028"
              height="17.028"
              viewBox="0 0 17.028 17.028"
            >
              <path
                id="Icon_material-close"
                data-name="Icon material-close"
                d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                transform="translate(-7.5 -7.5)"
                fill="#fff"
              />
            </svg>
          </b-button>
        </template>
        <div class="messageError">{{ getAvoirError }}</div>
        <template #modal-footer>
          <div class="actionModel">
            <b-button variant="danger" @click="hideModal('ModelError')">
              <div class="block-spinner">
                {{ $t("CLOSE") }}
              </div>
            </b-button>
          </div>
        </template>
      </b-modal>

      <b-pagination
        v-model="page"
        :total-rows="getfactureRows"
        :per-page="perPage"
        aria-controls="my-table"
        pills
        last-number
        first-number
        align="center"
        size="sm"
        @change="pagination"
        class=""
      ></b-pagination>
    </div>
    <div class="text-center center-spin">
      <b-spinner
        v-if="getFactureLoading"
        variant="primary"
        label="Spinning"
        class="big-spin"
      ></b-spinner>
    </div>

    <b-modal id="modal-1" ref="modalfact" title="Facture Type">
      <form @submit.prevent="facture">
        <div class="center">
          <b-form-group :label="$t('TYPE-FAC')">
            <b-form-select
              v-model="fac.type"
              :options="factypeoption"
            ></b-form-select>
          </b-form-group>
          <b-form-group
            :label="$t('TOT-TYP')"
            v-if="fac.type == 'facture.acompte'"
          >
            <b-form-select
              v-model="fac.total_type"
              :options="factotalType"
            ></b-form-select>
          </b-form-group>
          <b-form-group
            :label="$t('VALEUR')"
            v-if="fac.total_type == 'ht' && fac.type == 'facture.acompte'"
          >
            <b-form-input
              type="number"
              v-model="fac.valeur"
              placeholder="Écrire la valeur de total type"
              min="0"
              :max="this.fac.restht"
              step="0.1"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            :label="$t('VALEUR')"
            v-if="fac.total_type == 'ttc' && fac.type == 'facture.acompte'"
          >
            <b-form-input
              type="number"
              v-model="fac.valeur"
              placeholder="Écrire la valeur de total type"
              min="0"
              :max="this.fac.restttc"
              step="0.1"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            :label="$t('VALEUR')"
            v-if="
              fac.total_type == 'pourcentage' && fac.type == 'facture.acompte'
            "
          >
            <b-form-input
              type="number"
              v-model="fac.valeur"
              placeholder="Écrire la valeur de total type"
              min="0"
              step="0.1"
              :max="this.fac.restttc_pourcentage"
              required
            ></b-form-input>
          </b-form-group>
        </div>
      </form>
      <template #modal-footer="{ close }">
        <div class="double">
          <b-button variant="danger" @click="close">
            <div class="block-spinner">
              {{ $t("ANNULER") }}
            </div>
          </b-button>
          <b-button variant="success" @click="facture()">
            <div class="block-spinner">
              {{ $t("CONFIRM") }}
              <div v-if="getFactureLoading" class="loading ml-2">
                <div class="spinner-border" role="status"></div>
              </div>
            </div>
          </b-button>
        </div>
      </template>
    </b-modal>
    <CommentMarche to="/how-it-work/facture"></CommentMarche>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { domains } from "@/environment";
import moment from "moment";
import searchInput from "../components/ui/searchInput.vue";
import formatMoney from "accounting-js/lib/formatMoney.js";
import searchBar from "../components/ui/searchBar.vue";
import CommentMarche from "../components/commentMarche.vue";

export default {
  components: {
    searchBar,
    searchInput,
    CommentMarche,
  },
  data() {
    return {
      data: {
        brouillon: 1,
        type_devis: "",
        modele_id: "14",
      },
      fac: {
        type: null,
        total_type: null,
        valeur: null,
        restht: null,
        restttc: null,
        restttc_pourcentage: null,
      },
      factypeoption: [
        { value: "facture.acompte", text: "Facture Acompte" },
        { value: "facture.fin-travaux", text: "Facture Fin Travaux" },
      ],
      factotalType: [
        { value: "pourcentage", text: "Acompte Pourcentage" },
        { value: "ttc", text: "Acompte ttc" },
      ],
      readActivated: [
        {
          bool: false,
        },
      ],
      start: null,
      end: null,
      context: null,
      search: null,
      factureToDelete: null,
      factureDetail: null,
      reddescription: false,
      redremarque: false,
      redcondition_paiment: false,
      page: 1,
      perPage: 10,
      downloadUrl: domains.download,
      pdfInfo: null,
      searchC: null,
      searchBlock: false,
      current_entreprise: null,
      filtre_entreprise: null,
      errorD: false,
      errorS: false,
      showGroup: [
        {
          bool: true,
        },
      ],
      box: "",
      signedLoader: false,
      downloadLoader: false,
      filtre_client: null,
      currentclient: null,
    };
  },
  methods: {
    ...mapActions([
      "factures",
      "delete_facture",
      "downloadFacture",
      "doubleFacture",
      "store_facture",
      "getonefacture",
      "all_clients",
      "email_facture",
      "all_entreprises",
      "signed_Facture",
      "store_avoir",
      "all_clients",
      "store_devis",
     
      "addGroupe"
    ]),
    async createDevis(typeDevis) {
      try {
        this.data.type_devis = typeDevis;
        this.data.entreprise_id = this.getOnlineUser.entreprise.id;
        const response = await this.store_devis(this.data);
        const groupes = {
          document_id: response.data?.id,
          type_group: typeDevis,
        };
        if (typeDevis != "BAR-TH-175") {
          await this.addGroupe(groupes);
        }
        this.id = response.data?.devis?.id;
        this.$router.push(`/facture/manuelle/${this.id}`);
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        throw new Error(error);
      }
    },
    onContext(ctx) {
      this.context = ctx;
    },

    updateSearch(s) {
      this.page = 1;
      this.search = s;
    },

    handleStore() {
      this.$router.push("/facture/manuelle");
    },

    moment: function (date) {
      return moment(new Date(date)).locale(this.getSelectedLang.iso_code);
    },

    closeGroup(x) {
      this.showGroup[x].bool = !this.showGroup[x].bool;
    },

    selectentreprise(query) {
      if (query) {
        this.filtre_entreprise = query;
      } else {
        this.filtre_entreprise = {
          lib: null,
        };
      }
    },
    selectClient(query) {
      this.filtre_client = query;
    },

    resetDate() {
      this.start = null;
      this.end = null;
      this.factures({
        page: this.page,
        per_page: this.perPage,
        search: this.search,
      });
      this.$refs["Filtre"].hide();
    },

    closeFilter() {
      this.factures({
        page: this.page,
        per_page: this.perPage,
        search: this.search,
        start_date: this.start,
        end_date: this.end,
      });
      this.$refs["Filtre"].hide();
    },

    hideModal(ref) {
      this.$refs[ref].hide();
    },

    handleDelete(CAT) {
      this.factureToDelete = { ...CAT };
      this.$root.$emit("bv::show::modal", "ModelDelete");
    },

    pagination(paginate) {
      this.page = paginate;
      this.factures({
        page: this.page,
        per_page: this.perPage,
        search: this.search,
        entreprise: this.current_entreprise,
      });
    },

    handleFacture(CAT) {
      this.factureDetail = null;
      this.factureDetail = { ...CAT };
      this.fac.restht = this.factureDetail.facture.reste_a_facturer_HT;
      this.fac.restttc = this.factureDetail.facture.reste_a_facturer_HT;
      this.fac.restttc_pourcentage =
        this.factureDetail.facture.reste_a_facturer_TTC /
        (this.factureDetail.facture.total_TTC / 100);
      this.creategroup();
      if (
        [
          "BAR-EN-101",
          "BAR-EN-102",
          "BAR-EN-104",
          "BAR-EN-103",
          "Type Complexe",
          "BAR-TH-101",
          "BAR-TH-112",
          "BAR-TH-113",
          "BAR-TH-143",
          "BAR-TH-171",
          "BAR-TH-127",
          "BAR-TH-129",
          "BAR-TH-148",
          "BAR-TH-137",
          "BAR-TH-173",
          "Libre",
          "renovation d'ampleur",
          // "BAR-TH-174",
          // "BAR-TH-175",
          "facture.fin-travaux",
          "facture.acompte"
        ].includes(CAT.facture.type)
      ) {
        this.$router.push("/documents/" + CAT.facture.devis.id);
      } else {
        this.$refs["Modeldevis"].show();
      }
      this.$refs["factureModel"].show();
    },

    async signed() {
      if (this.factureDetail.is_signed != "pending") {
        this.signedLoader = true;
        await this.signed_Facture({
          doc: this.factureDetail.groupeLigneDocument[0].document_id,
        })
          .then(() => {
            this.signedLoader = false;
            this.box = "";
            this.$bvModal.msgBoxOk(this.$t("YOUSIGN_SENDED"), {
              title: "Confirmation",
              size: "sm",
              buttonSize: "sm",
              okVariant: "success",
              headerClass: "p-2 border-bottom-0",
              centered: true,
            });
            this.hideModal("factureModel");
          })
          .catch(() => {
            this.signedLoader = false;
            this.$refs["ModelError"].show();
          });
      } else {
        this.showMsgBox();
      }
    },

    openDev() {
      this.$router.push(
        "/devis/list?id=" + this.factureDetail.facture.devis.id
      );
    },

    goToAcompte(acompte) {
      this.$router.push("/acompte/list?id=" + acompte.id);
    },

    generate(facture) {
      this.downloadLoader = true;
      var id = 0;
      if (!facture) {
        id = this.factureDetail.facture.id;
      } else {
        id = facture.facture.id;
      }
      this.downloadFacture(id)
        .then((response) => {
          this.downloadLoader = false;
          this.pdfInfo = response;
          this.download();
        })
        .catch(() => {
          this.downloadLoader = false;
          this.$refs["ModelError"].show();
        });
    },
    openfact() {
      this.$refs["modalfact"].show();
    },
    showMsgBoxOne() {
      this.$bvModal
        .msgBoxConfirm("Êtes-vous sûrs ?", {
          buttonSize: "sm",
          okVariant: "success",
          cancelVariant: "danger",
          okTitle: `${this.$t("YES")}`,
          cancelTitle: `${this.$t("NO")}`,
        })
        .then((value) => {
          if (value) {
            this.store_avoir({
              facture_id: this.factureDetail.facture.id,
            })
              .then(() => {
                this.$router.push("/avoir/list");
              })
              .catch(() => {
                this.$refs["ModelError"].show();
              });
          }
        });
    },
    facture() {
      var facture = {
        document_id: this.factureDetail.id,
        modele_id: this.factureDetail.facture.modele.id,
        type_facture: this.fac.type,
        total_type: this.fac.total_type,
        condition_paiment: this.factureDetail.facture.condition_paiment,
        remarque: this.factureDetail.facture.remarque,
      };

      if (this.fac.total_type == "pourcentage") {
        facture.total_percent = this.fac.valeur;
      } else if (this.fac.total_type == "ht") {
        facture.total_HT = this.fac.valeur;
      } else facture.total_TTC = this.fac.valeur;
      this.store_facture(facture).then((response) => {
        if (response) {
          const factures = response.data.data.facture;
          const lastFacture = factures[factures.length - 1];
          if (lastFacture) {
            this.$router.push("/facture/list?id=" + lastFacture.id);
          } else {
            this.$router.push("/facture/list");
          }
          this.hideModal("modalfact");
          this.hideModal("factureModel");
          this.factures({
            page: this.page,
            per_page: this.perPage,
            search: this.search,
            start_date: this.start,
            end_date: this.end,
          });
        } else alert("Erreur !");
      });
    },
    activateReadMore(y) {
      this.readActivated[y].bool = !this.readActivated[y].bool;
    },
    download() {
      window.open(this.downloadUrl + this.pdfInfo, "_blank");
    },

    sendEmail() {
      this.email_facture(this.factureDetail.facture.id)
        .then(() => {
          this.box = "";
          this.$bvModal.msgBoxOk(this.$t("FACTURE_SENDED"), {
            title: "Confirmation",
            size: "sm",
            buttonSize: "sm",
            okVariant: "success",
            headerClass: "p-2 border-bottom-0",
            centered: true,
          });
        })
        .catch(() => {
          this.$refs["ModelError"].show();
        });
    },

    showMsgBox() {
      this.box = "";
      this.$bvModal
        .msgBoxConfirm(`${this.$t("YOUSIGN_SENDED_WARNIN")}`, {
          title: `${this.$t("CONFIRM")}`,
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          cancelVariant: "danger",
          okTitle: `${this.$t("YES")}`,
          cancelTitle: `${this.$t("NO")}`,
          footerClass: "p-2",
          hideHeaderClose: false,
        })
        .then((value) => {
          if (value) {
            this.signed_Facture({
              doc: this.factureDetail.groupeLigneDocument[0].document_id,
            })
              .then(() => {
                this.box = "";
                this.$bvModal.msgBoxOk(this.$t("YOUSIGN_SENDED"), {
                  title: "Confirmation",
                  size: "sm",
                  buttonSize: "sm",
                  okVariant: "success",
                  headerClass: "p-2 border-bottom-0",
                  centered: true,
                });
                this.hideModal("factureModel");
              })
              .catch(() => {
                this.$refs["ModelError"].show();
              });
          }
        });
    },

    storeFacture() {
      this.$router.push({
        path: "/facture/manuelle",
        query: { entreprise: this.$route.query.entreprise },
      });
    },
    creategroup() {
      for (var i = 0; i < this.factureDetail.groupeLigneDocument.length; i++) {
        this.showGroup.push({
          bool: true,
        });
        this.readActivated.push({
          bool: false,
        });
      }
    },

    totalHT(item) {
      const totalSansRemise = item.unit_price * item.quantite;
      if (Math.round(totalSansRemise * 100) / 100 < 0) return 0;
      return Math.round(totalSansRemise * 100) / 100;
    },

    totalAR(item) {
      const totalSansRemise = this.totalHT(item);
      var totalAvecRemise = totalSansRemise;
      if (item.remise_type == "remise.HT")
        totalAvecRemise -= item.remise_value ? +item.remise_value : 0;
      else totalAvecRemise -= (item.remise_value * totalSansRemise) / 100;

      if (totalAvecRemise < 0) return 0;
      return Math.round(totalAvecRemise * 100) / 100;
    },

    setup(refresh = false) {
      if (refresh) this.page = 1;
      this.search = null;

      this.current_entreprise = this.filtre_entreprise;
      this.currentclient = this.filtre_client;
      this.$refs["entrepriseModal"].hide();

      if (this.$route.query.entreprise) {
        this.factures({
          page: this.page,
          per_page: this.perPage,
          entreprise: this.$route.query.entreprise,
          client: this.currentclient ? this.currentclient.id : null,
        });
      } else {
        if (this.isSuperAdmin) {
          this.factures({
            page: this.page,
            per_page: this.perPage,
            entreprise: this.current_entreprise,
            client: this.currentclient ? this.currentclient.id : null,
          });
        } else {
          this.factures({
            page: this.page,
            per_page: this.perPage,
            entreprise: this.getOnlineUser.entreprise,
            client: this.currentclient ? this.currentclient.id : null,
          });
        }
      }
    },
  },

  mounted() {
    this.setup(true);
    if (this.isSuperAdmin)
      this.all_entreprises({ page: this.page, per_page: this.perPage });
    if (this.$route.query.id) {
      this.getonefacture(this.$route.query).then((response) => {
        if (response) this.handleFacture(this.getFacture);
        else alert("Facture Not Found !");
      });
    }
    this.all_clients({ page: 1, per_page: 1000 });
  },

  computed: {
    ...mapGetters([

      "getOnlineUser",
      "getAllFacture",
      "getFactureLoading",
      "getfactureRows",
      "getTabs",
      "getToken",
      "getFacture",
      "getAllclients",
      "getclientLoading",
      "getSelectedLang",
      "getAllentreprises",
      "getentrepriseLoading",
      "getAvoirError",
      "getAllclients",
      "getclientLoading",
    ]),

    TVA() {
      var tva = {};
      this.factureDetail.GroupeLigneDocument_produit.forEach((product) => {
        let ht = 0;
        ht = ht + parseFloat(product.quantite) * product.unit_price;

        if (tva[product.tva]) {
          tva[product.tva] += (ht * product.tva) / 100;
        } else {
          tva[product.tva] = (ht * product.tva) / 100;
        }
      });
      return Object.keys(tva).map(function (key) {
        return {
          tva: key,
          value: Math.round(tva[key] * 100) / 100,
        };
      });
    },
    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
    isentrepriseAdmin() {
      return this.getOnlineUser.role == "user.entreprise-admin";
    },
    isAssistant() {
      return this.getOnlineUser.role == "user.assistant";
    },
    isCommercial() {
      return this.getOnlineUser.role == "user.commercial-entreprise";
    },
    isClient() {
      return this.getOnlineUser.role == "user.client";
    },
    isComptable() {
      return this.getOnlineUser.role == "user.comptable";
    },

    searchBarText() {
      return `${this.$t("SEARCH_BY_CLIENT")} ( ${this.$t("NAME")}, ${this.$t(
        "SURNAME"
      )}, ${this.$t("EMAIL")} )`;
    },
  },

  filters: {
    financial: function (value, noSymbol = false) {
      return formatMoney(value, {
        symbol: noSymbol ? "" : "€",
        thousand: " ",
        precision: 2,
        decimal: ",",
        format: "%v %s",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.double {
  button {
    width: 48%;
  }
}

.grey {
  background-color: #f5f6f8;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  margin: 0 auto;
}

.content {
  .content-header {
    .input-group {
      @media only screen and (max-width: 900px) {
        width: 59%;
      }
      width: 80%;
      position: relative;
      margin-bottom: 0;
      .icon-search {
        @media only screen and (max-width: 900px) {
          display: none;
        }
        overflow: visible;
        position: absolute;
        left: 15px;
        top: 10px;
        color: #515151;
      }

      .icon-balance {
        cursor: pointer;
        overflow: visible;
        position: absolute;
        right: 15px;
        top: 10px;
        color: #28367a;
        outline: 0;

        .balance {
          color: #28367a;
        }
      }
      .form-control::placeholder {
        color: #515151;
        font-size: 12px;
      }
    }

    .filtre {
      @media only screen and (max-width: 900px) {
        width: 39%;
      }
      width: 19%;
      background-color: #28367a;
      color: #fff;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 4px;
      border-radius: 5px;
      font-size: 12px;
    }
  }

  & p {
    font-size: 12px;
    font-weight: 500;
    margin: 8px 15px 0 0;
  }
}

.body {
  padding: 15px;
  box-shadow: 1px 1px 24px #00000019;
  max-height: 60vh;
  overflow: auto;

  ul {
    list-style: none;
    & li {
      padding: 8px 0;
      cursor: pointer;
      background-color: #f8f8f8;
      margin-bottom: 4px;
      color: #000;
      border: none;
      box-shadow: none;
      transition: all 0.2s ease;
      text-align: center;

      &:hover {
        background-color: #28367a;
        color: #fff;
      }
    }
  }
}
.justify {
  padding: 13px;
  .left {
    h2 {
      color: #28367a;
      font-weight: 700;
      margin-bottom: 0px;
    }
    .entrepriseinfo {
      color: #000;
      font-weight: 600;
    }
    .title {
      color: #28367a;
      font-weight: 700;
    }
    .adressinfo {
      display: grid;
    }
    .info {
      color: #515151;
    }
    .block {
      margin-top: 10px;
    }
  }
  .right {
    .infonum {
      display: grid;
    }
    .span {
      color: #515151;
    }
    h2 {
      color: #28367a;
      font-weight: 700;
    }
    .adressinfo {
      display: grid;
    }
    .info {
      color: #515151;
    }
    .entrepriseinfo {
      color: #000;
      font-weight: 600;
    }
  }

  .divborder {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    overflow: hidden;
    color: #28367a;
    width: 100%;
    p {
      margin: 0px 10px;
    }
  }
  .divborder::after,
  .divborder::before {
    content: "";
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
    height: 1px;
    background-color: #d8d8d8;
  }
}

.w-100 {
  background-color: #f8f8f8;
  padding: 4px;
  overflow-x: auto;
}

body {
  background: #fafdff;
}

.detailClient {
  background-color: #fff;

  box-shadow: 1px 1px 24px #00000019;

  .clientTop {
    padding: 16px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-bottom: 1px solid #707070;

    & .prodimg {
      max-width: 185px;
      max-height: 231px;
    }

    .star {
      color: #ffc400;
      position: absolute;
      top: 12px;
      right: 8px;
      font-size: 30px;
    }
  }

  .clientMid {
    padding: 16px 8px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: 1px solid #707070;

    & > div {
      width: 50%;
    }
  }

  .clientBot {
    padding: 16px 8px;
  }

  & h4 {
    color: #28367a;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
  }

  & p {
    color: #515151;
    font-size: 16px;
    font-weight: 500;
  }
}
.filtre {
  display: flex;
  align-items: center;
  gap: 6px;

  .productTrie {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    overflow: hidden;
    padding: 4px 8px;
    border-radius: 32px;
    background-color: #34c38f;
    color: #fff;
    margin: 2px 0 !important;

    .icons {
      cursor: pointer;
      width: 18px;
      height: 18px;
      margin-left: 4px;
    }
  }
}

.group {
  box-shadow: 0px 2px 6px #00000019;
  background-color: #fff;
  margin-bottom: 15px;
}

.spinner-border {
  width: 1.2rem;
  height: 1.2rem;
}
.groupEdit {
  border: 1px solid #707070;
  padding: 8px;
  border-radius: 5px;
  margin-bottom: 16px;
  box-shadow: 0 1px 2px 0 #707070;
  width: 100%;

  .entete {
    font-weight: 400;
    font-size: 12px;
    background-color: #28367a;
    padding: 4px;
    color: #fff;
    text-align: center;
    margin-bottom: 8px;
  }
}
.input-group {
  margin-bottom: 8px;
  .icon-search {
    position: absolute;
    left: 15px;
    top: 10px;
  }
}

.cours {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;

  p {
    margin: 0;
    color: #fff;
    background-color: #d0cccc;
    width: 81px;
    height: 24px;
    font-size: 13px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button {
    font-size: 14px;
  }
}
.justify {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.part {
  padding: 8px 0;
  span {
    font-size: 14px;
    font-weight: 500;
    color: #515151;
    word-wrap: break-word;
  }
  .remarqueblock {
    margin-bottom: 5px;
  }
  .cadre {
    background-color: #f8f8f8;
    margin-top: 1%;
    margin-bottom: 1%;
    box-shadow: 0 2px 6px rgb(0 0 0 / 10%);
    padding: 13px;
  }
  .left {
    @media only screen and (max-width: 900px) {
      width: 100%;
    }
    width: 30%;
  }

  .right {
    @media only screen and (max-width: 900px) {
      width: 100%;
    }
    width: 30%;
    p {
      float: right;
      padding: 8px;
      background-color: #d0cccc;
      color: #000;
      width: 50%;
      font-weight: 600;
      text-align: center;
      margin: 0 0 4px;
    }
  }

  .fact {
    font-size: 16px;
    font-weight: 600;
    color: #28367a;
  }

  h2,
  h3 {
    font-weight: 600;
  }

  h2 {
    font-size: 16px;
  }
  h3 {
    font-size: 16px;
    margin: 0;
  }

  .part-title {
    color: #28367a;
    font-weight: 700;
    margin-right: 10px;
  }

  ul {
    list-style: none;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #515151;

      span {
        display: block;
        width: 50%;
        font-size: 14px;
      }

      .title {
        @media only screen and (max-width: 900px) {
          font-size: 12px;
        }
        color: #393939;
        font-weight: 600;
        font-size: 14px;
      }
    }

    .traveauxtitle {
      @media only screen and (max-width: 900px) {
        padding: 0;
      }
      padding: 0 15px;
    }
  }
  .flexEnd {
    display: flex;
    justify-content: space-between;
    .sousTraitant {
      p {
        color: #2167d9;
      }
    }
    .subtable {
      .complet {
        @media only screen and (max-width: 900px) {
          margin-right: 10%;
          width: 100%;
        }
        border-collapse: separate;
        margin-right: 10%;
        width: 100%;
        height: 32px;
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        tr {
          height: 24px;
        }

        .thead {
          @media only screen and (max-width: 900px) {
            padding: 6px !important;
          }
          background-color: #6472b3;
          color: #fff;
          max-width: 118px;
          height: 32px;
        }
        td {
          color: #000;
          background-color: #e9eaf2;
          width: 118px;
        }
      }
    }
  }

  .tabledonne {
    overflow-x: auto;
    margin-bottom: 4px;
    .libblock {
      font-weight: 800;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  table {
    border-collapse: separate;
    th {
      font-weight: 400;
      font-size: 12px;
      background-color: #6472b3;
      padding: 4px;
      color: #fff;
      border: 1px solid #6472b3;
      text-align: center;
    }
    td {
      text-align: center;
      padding: 4px;
      background-color: #e9eaf2;
    }
    tr {
      height: 24px;
    }
  }
}

.flexEndTotale {
  display: flex;
  justify-content: flex-end;
  .subtableTotale {
    .completTotale {
      @media only screen and (max-width: 900px) {
        margin-right: 10%;
        width: 100%;
      }
      border-collapse: separate;
      margin-right: 10%;
      width: 100%;
      height: 32px;
      text-align: center;
      font-size: 12px;
      font-weight: bold;
      tr {
        height: 24px;
      }

      .theadTotale {
        @media only screen and (max-width: 900px) {
          padding: 6px !important;
        }
        background-color: #28367a;
        color: #fff;
        max-width: 118px;
        height: 32px;
      }
      td {
        color: #000;
        background-color: #e9eaf2;
        width: 135px;
      }
    }
  }
}

.buttondevis {
  @media only screen and (max-width: 900px) {
    width: 100%;
    padding: 10px 0;
    justify-content: center;
    flex-wrap: wrap;
  }
  width: 95%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  padding: 20px 0;
  border-top: 1px solid #d8d8d8;
  gap: 15px 0;

  button {
    @media only screen and (max-width: 900px) {
      min-width: 47%;
      max-width: 47%;
      font-size: 12px;
      margin: 4px 4px 0 0;
      padding: 0;
    }
    min-width: 24%;
    height: 42px;
    font-size: 14px;

    .block-spinner {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.group {
  @media only screen and (max-width: 900px) {
    overflow: hidden;
    padding: 8px;
  }
  box-shadow: 0px 2px 6px #00000019;
  background-color: #fff;
  border-radius: 0 0 5px 5px;
  margin-bottom: 15px;
  .part {
    padding: 15px;
    margin-bottom: 0;
    overflow: auto;
  }
}
.piece {
  background-color: #28367a;
  color: #fff;
  padding: 0px 15px;
  border-radius: 5px 5px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  div:first-child {
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  p {
    margin: 0;
    font-size: 14px;
    margin-right: 8px;
    overflow: hidden;
  }
  .icone-down-up {
    display: flex;
    align-items: center;
    p,
    span {
      @media only screen and (max-width: 900px) {
        display: none;
      }
    }

    button {
      background-color: transparent !important;
      border: transparent;
      box-shadow: none;
    }

    span {
      font-size: 14px;
      background-color: #6472b3;
      border-radius: 4px;
      padding: 2px 12px;
    }
  }
}

.modal-test {
  width: 95%;
  margin: auto;
}
.description-container {
  font-size: 14px;
  color: #515151;
  .title-description {
    color: #28367a;
    font-weight: 700;
    margin-right: 10px;
  }
}
.tabledonne td.blocklibdesc {
  text-align: left;

  .descblock {
    white-space: normal;
    max-width: 400px;
    height: 72px;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.575;
    position: relative;
  }
  .read {
    position: absolute;
    bottom: -2px;
    right: 0;
    display: inline-block;
    font-size: 12px;
    font-weight: 800;
    color: #28367a;
  }
}
.description-container {
  font-size: 14px;
  color: #515151;
  .title-description {
    color: #28367a;
    font-weight: 700;
    margin-right: 10px;
  }
}
.textstyle {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.auto-heigth {
  height: auto !important;
}

.accompteTable {
  td {
    background: #e9eaf2;
    border: 1px solid #fff;
  }
}
</style>
